<template>
  <div v-if="!this.$authentication.loading">
    <div v-if="this.getProfile">
      <h3 class="font-weight-500">Tu confirmación de compra será enviado a:</h3>
      <h3 class="font-weight-500" style="color: #000">
        {{ this.getProfile ? this.getProfile.email : '' }}
      </h3>
    </div>
    <div v-else>
      <div v-if="createAccount">
        <h3 class="font-weight-500">
          Solo necesita crear una cuenta para que puedas comprar nuestros cursos
          y empezar tu aprendizaje.
        </h3>
        <validation-observer ref="formValidator" v-slot="{ handleSubmit }">
          <form
            class="form-checkout"
            role="form"
            @submit.prevent="handleSubmit(onSubmitRegister)"
          >
            <md-field>
              <label class="auth-container__label">Nombre completo</label>
              <md-input
                v-model="userData.name"
                alternative
                name="Name"
                class="checkout-register"
              ></md-input>
            </md-field>

            <div v-if="errors.name" class="error-message-register">
              <span>
                {{ errors.name }}
              </span>
            </div>

            <md-field>
              <label class="auth-container__label">Correo electrónico</label>
              <md-input
                v-model="userData.email"
                :rules="{ email: true }"
                alternative
                name="Email"
                class="checkout-register"
                @blur="validateEmail"
              ></md-input>
            </md-field>

            <div v-if="errorEmailExist" class="error-message-register">
              <span>
                {{ errorEmailExist }}
              </span>
            </div>

            <div v-if="errors.email" class="error-message-register">
              <span>
                {{ errors.email }}
              </span>
            </div>

            <md-field class="input-password-container">
              <label class="auth-container__label">Contraseña</label>
              <md-input
                id="model.password"
                v-model="userData.password"
                :strength="validPassword.strong"
                class="input-password checkout-register"
                type="password"
                @input="passwordValidation"
              ></md-input>
            </md-field>
            <vue-password
              id="model.vue-password"
              v-model="userData.repeatPassword"
              :disable-toggle="true"
              :strength="validPassword.strong"
              class="control has-icons-left md-input input-password"
              placeholder="repetir Contraseña"
              type="password"
              @input="passwordValidation"
            />
            <div
              v-if="userData.password && errors.password"
              class="error-message-register"
            >
              <span>
                {{ errors.password }}
              </span>
            </div>
            <md-field class="input-password-container">
              <label class="auth-container__label">Repetir Contraseña</label>
              <md-input
                id="model.repetPassword"
                v-model="userData.repeatPassword"
                :strength="validPassword.strong"
                class="control has-icons-left md-input input-password checkout-register"
                type="password"
                @input="validateRepeatPassword"
              ></md-input>
            </md-field>

            <div
              v-if="userData.repeatPassword && errors.repeatPassword"
              class="error-message-register"
            >
              <span>
                {{ errors.repeatPassword }}
              </span>
            </div>

            <md-field>
              <vue-tel-input
                v-model="userData.phone"
                :input-options="inputOptions"
                mode="international"
                class="tel-register-input"
                :auto-default-country="true"
                @input="validatePhone"
              ></vue-tel-input>
            </md-field>
            <div v-if="errors.phone" class="error-message-register">
              <span>
                {{ errors.phone }}
              </span>
            </div>
            <md-field class="mb-3 mt-0">
              <multiselect
                v-model="userData.country"
                :close-on-select="true"
                :options="getAllCountries"
                :searchable="true"
                :show-labels="false"
                class="select-danger details-country shadow__multiselect checkout-register border-none"
                label="name"
                placeholder="Seleccione un País"
                track-by="id"
              >
                <template #noResult>
                  <div>Sin resultados</div>
                </template>
                <template #option="props">
                  <div class="option__desc">
                    <span class="option__title">{{
                      `(${props.option.code}) ${props.option.name}`
                    }}</span>
                    <span
                      v-if="
                        props.values &&
                        props.values.length &&
                        !props.values.isOpen
                      "
                      class="multiselect__single"
                      >{{ props.values.length }} options selected</span
                    >
                  </div>
                </template>
              </multiselect>
            </md-field>

            <div class="text-center">
              <base-button
                :disabled="
                  !(
                    validPassword.strong > 2 &&
                    userData.password === userData.repeatPassword &&
                    userData.password.length > 7 &&
                    userData.email &&
                    userData.name
                  )
                "
                :loading="isLoadingRegister"
                block
                class="my-3"
                native-type="submit"
                type="dark"
                >Continuar
              </base-button>
            </div>
          </form>
        </validation-observer>

        <p class="text-center">
          ¿Ya tienes una cuenta?
          <span class="register-user-checkout" @click="registerUser">
            Inicia sesión
          </span>
        </p>
      </div>
      <div v-else>
        <validation-observer ref="formValidator" v-slot="{ handleSubmit }">
          <form
            class="login-form-checkout"
            role="form"
            @submit.prevent="handleSubmit(onSubmitLogin)"
          >
            <md-field class="mb-0">
              <label class="auth-container__label">Correo electrónico</label>
              <md-input
                v-model="userData.email"
                :rules="{ email: true }"
                alternative
                class="mb-0 checkout-register"
                name="Email"
              ></md-input>
            </md-field>

            <md-field class="mt-0 input-password-container">
              <label class="auth-container__label">Contraseña</label>
              <md-input
                v-model="userData.password"
                alternative
                class="mb-0 input-password checkout-register"
                name="Password"
                type="password"
              ></md-input>
            </md-field>

            <div
              class="after-login-form row ckeckout-form justify-content-between"
            >
              <div class="remember-user col-xs-5 rememberMe">
                <base-switch v-model="userData.remember_me" type="dark"
                  >Recuerdame
                </base-switch>
                <span class="text-default rememberMe" style="margin-left: 10px"
                  >Recuerdame</span
                >
              </div>
              <div class="col-xs-5 fg-password">
                <router-link
                  class="forgot-passwor text-decoration-none"
                  to="/recordar"
                >
                  <small class="text-default rememberMe" style="margin-top: 3px"
                    >Contraseña olvidada</small
                  >
                </router-link>
              </div>
            </div>

            <div class="text-center">
              <base-button
                :loading="isLoadingRegister"
                block
                class="mt-4"
                native-type="submit"
                type="dark"
                >Continuar
              </base-button>
            </div>
          </form>
        </validation-observer>
        <div class="d-flex my-3 justify-content-center">
          <p class="mr-1 mb-0">
            ¿No tienes una cuenta?
            <span class="mr-1 mb-0 create-account-pointer" @click="registerUser"
              >Crea tu cuenta
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <loading-panel style="z-index: 6; height: 250px" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VuePassword from '../../../../../components/VuePassword';
import BaseButton from '../../../../../components/BaseButton';
import LoadingPanel from '../../../../../components/LoadingPanel';
import { AUTH_REQUEST } from '../../../../../store/actions/login';
import {
  REGISTER_REQUEST,
  USER_EMAIL_VERIFICATION,
} from '../../../../../store/actions/register';
import { CHECKOUT_CHANGE_STEP } from '../../../../../store/actions/checkout';
import { CHECKOUT_SECOND_STEP } from '../../../../../util/constants';
import { USER_REQUEST } from '../../../../../store/actions/user';
import Multiselect from 'vue-multiselect';
import validator from 'validator';
import { VueTelInput } from 'vue-tel-input/dist/vue-tel-input.common';
import 'vue-tel-input/dist/vue-tel-input.css';
import equals from 'validator/lib/equals';

export default {
  name: 'CheckoutUserCart',
  components: {
    VuePassword,
    BaseButton,
    LoadingPanel,
    Multiselect,
    VueTelInput,
  },
  data() {
    return {
      vm: this,
      createAccount: false,
      isLoadingAccount: true,
      isLoadingRegister: false,
      rules: [
        { message: 'Se requiere una letra minúscula.', regex: /[a-z]+/ },
        { message: 'Se requiere una letra mayúscula.', regex: /[A-Z]+/ },
        { message: 'Se requiere un número.', regex: /[0-9]+/ },
        { message: '8 caracteres como mínimo.', regex: /.{8,}/ },
      ],
      validPassword: {
        strong: 0,
        message: '',
        alert: '',
      },
      errorMessage: null,
      userData: {
        name: '',
        email: '',
        password: '',
        remember_me: false,
        phone: '',
        repeatPassword: '',
        agree: false,
        country: '',
      },
      EmailVerification: '',
      errorEmailExist: '',
      inputOptions: {
        placeholder: 'Teléfono (Opcional)',
      },
      errors: {},
    };
  },
  computed: {
    ...mapGetters(['getProfile', 'getAllCountries', 'getUserRequestError']),
  },
  watch: {
    getProfile: function (newValue) {
      if (newValue.email) {
        this.$store.dispatch(CHECKOUT_CHANGE_STEP, CHECKOUT_SECOND_STEP);
      }
    },
  },
  mounted() {
    this.checkIfExistsProfile();
  },
  methods: {
    checkIfExistsProfile() {
      if (this.getProfile) {
        this.$store.dispatch(CHECKOUT_CHANGE_STEP, CHECKOUT_SECOND_STEP);
      }
    },
    registerUser() {
      this.createAccount = !this.createAccount;
    },
    onSubmitRegister() {
      this.isLoadingRegister = true;
      this.$store
        .dispatch(REGISTER_REQUEST, {
          name: this.userData.name,
          email: this.userData.email,
          password: this.userData.password,
          repeatPassword: this.userData.repeatPassword,
          country_id: this.userData.country ? this.userData.country.id : '',
          phone: this.userData.phone,
        })
        .then(() => {
          window.location.reload();
        })
        .catch(() => {
          this.isLoadingRegister = false;
        });
    },
    onSubmitLogin() {
      this.isLoadingRegister = true;
      this.$store
        .dispatch(AUTH_REQUEST, {
          email: this.userData.email,
          password: this.userData.password,
        })
        .then(() => {
          this.isLoadingRegister = false;
          this.$authentication.changeLoading(true);
          this.$store.dispatch(USER_REQUEST).then(() => {
            this.$authentication.changeLoading(false);
            this.$store.dispatch(CHECKOUT_CHANGE_STEP, CHECKOUT_SECOND_STEP);
          });
        })
        .catch(() => {
          this.isLoadingRegister = false;
        });
    },

    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    countryValidation() {
      if (!this.userData.country) {
        this.errors.country.id = 'El país es requerido';
      }
    },

    validatePassword() {
      if (!equals(this.userData.password, this.userData.repeatPassword)) {
        this.errors.password = 'Las contraseñas deben ser iguales';
      } else {
        delete this.errors.password;
      }
    },
    validateRepeatPassword() {
      if (this.userData.repeatPassword !== this.userData.password) {
        this.errors.repeatPassword = 'Las contraseñas deben ser iguales';
      } else {
        delete this.errors.repeatPassword;
      }
    },
    validateEmail() {
      let email = this.userData.email;
      this.$store
        .dispatch(USER_EMAIL_VERIFICATION, email)
        .then((resp) => {
          this.EmailVerification = resp.data;
          if (this.EmailVerification) {
            this.errorEmailExist =
              '¡Ya existe una cuenta con ese correo electrónico!';
          } else {
            this.errorEmailExist = null;
          }
        })
        .catch(() => {});

      if (!validator.default.isEmail(email)) {
        console.log('error email');
        this.errors.email = 'El formato del correo es incorrecto';
      } else {
        delete this.errors.email;
      }
    },
    validatePhone() {
      if (
        this.userData.phone &&
        !validator.default.matches(this.userData.phone, /^[0-9+\s]+$/)
      ) {
        this.errors.phone = 'El formato del teléfono es incorrecto';
      } else {
        delete this.errors.phone;
      }
    },
    passwordValidation() {
      let errors = [];
      let rules = [
        { message: 'Se requiere una letra minúscula.', regex: /[a-z]+/ },
        { message: 'Se requiere una letra mayúscula.', regex: /[A-Z]+/ },
        { message: 'Se requiere un número.', regex: /[0-9]+/ },
        { message: '8 caracteres como mínimo.', regex: /.{8,}/ },
      ];

      for (let condition of rules) {
        if (!condition.regex.test(this.userData.password)) {
          errors.push(condition.message);
        }
      }

      this.errors.password = errors.length > 0 ? errors.join(' ') : null;

      switch (errors.length) {
        case 0: {
          this.validPassword.strong = 3;
          this.validPassword.message = 'Segura';
          this.validPassword.alert = 'text-info';
          break;
        }
        case 1: {
          this.validPassword.strong = 2;
          this.validPassword.message = 'Medio';
          this.validPassword.alert = 'text-warning';
          break;
        }
        case 2: {
          this.validPassword.strong = 2;
          this.validPassword.message = 'Medio';
          this.validPassword.alert = 'text-warning';
          break;
        }
        case 3: {
          this.validPassword.strong = 1;
          this.validPassword.message = 'Debil';
          this.validPassword.alert = 'text-warning';
          break;
        }
        case 4: {
          if (this.password.length < 2) {
            this.validPassword.strong = 1;
            this.validPassword.message = 'Debil';
            this.validPassword.alert = 'text-warning';
          }
          break;
        }
        default: {
          this.validPassword.strong = 0;
          break;
        }
      }

      let strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/;

      if (strongPasswordRegex.test(this.userData.password) && this.userData.password.length > 11) {
        this.validPassword.strong = 4;
        this.validPassword.message = 'Muy Segura';
        this.validPassword.alert = 'text-success';
      }
    },
  },
};
</script>

<style>
.create-account-pointer
{
  color: #b7227e !important;
}
.multiselect-checkout-user-cart-countries .multiselect input {
  border: 0px;
}

::placeholder,
.multiselect__placeholder {
  color: #0000008a !important;
}

.register-user-checkout {
  background-color: #fff;
  color: #b7227e;
  cursor: pointer;
  padding: 0;
  outline: none;
  border: none;
  align-self: center;
}

.register-user-checkout:hover,
.register-user-checkout:focus,
.register-user-checkout:active {
  background-color: white !important;
  color: #b7227e !important;
  box-shadow: none !important;
}


.register-user-checkout-login {
  color: #b7227e;
  cursor: pointer;
  padding: 0;
}

.multiselect__input,
.multiselect__single {
  padding-left: 10px;
}

.multiselect__single {
  padding: 0px !important;
  margin-top: 13px !important;
  margin-bottom: 13px !important;
  border: none;
}
.multiselect__placeholder {
  padding-top: 0px !important;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 30px;
  font-size: 16px;
  letter-spacing: 0.01em;
}
.multiselect__select::before {
  top: 35%;
}
.multiselect__tags {
  padding: 0px 40px 0px 16px !important;
}
.multiselect__input {
  padding: 0px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 30px;
}

.form-checkout .md-field.md-theme-default label {
  color: rgba(0, 0, 0, 0.54);
}

.create-account-pointer {
  color: #000;
  cursor: pointer;
}
.border-none input {
  border: none !important;
}

.error-message-register,
.error-message-register span {
  background-color: rgb(226, 4, 4);
  color: white;
  width: 100%;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  margin-top: 14px !important;
  font-size: 14px !important;
}

.vue-tel-input.tel-register-input {
  width: 100%;
  height: 56px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.vue-tel-input.tel-register-input input::placeholder {
  color: #0000008a !important;
}

.tel-register-input.vti__input {
  font-weight: 400;
}

.tel-register-input.vti__input::placeholder {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
}

.tel-register-input.vue-tel-input:focus-within {
  outline: none;
  border-color: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-color: none !important;
  border-top-color: none !important;
  border-right-color: none !important;
  border-bottom-color: none !important;
  border-left-color: none !important;
  border: 1px solid #ddd;
}

.tel-register-input .vti__dropdown-item strong {
  font-weight: 400;
}

.vti__input input {
  margin-bottom: 0px !important;
}

.login-form-checkout .md-field input {
  border: 1px solid #ddd;
}
.multiselect__content-wrapper {
  top: auto !important;
  bottom: 100% !important;
}
</style>
